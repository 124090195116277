import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Register from './components/Register';
import Confirmation from './components/Confirmation';
import Default from './components/Default';
import InfoParam from './components/infoparam';
import './Content/CSS/custom.css';
import './Content/CSS/isi.css';
import './Content/CSS/home.css';
import './Content/CSS/footer.css';

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Default} />
            <Route exact path='/index.html' component={Default} />
            <Route path='/register' component={Register} />            
            <Route path='/receipt' component={Confirmation} />
            <Route path='/infoparam' component={InfoParam} />
            <Route path='/confirmation/:cc' component={Confirmation} />
            <Route path='/:ac' component={Register} />            
        </Switch>
    </Layout>
);
