import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import BioCryst from '../Content/Images/Logo/BioCryst_Logo_Reg.png';
import Orladeyo from '../Content/Images/Logo/Orladeyo_Logo.png';
import Dismiss from '../Content/Images/icons/icon_dismiss.png';
import Empower from '../Content/Images/Logo/EMPOWER_Logo.png';
import { withRouter } from "react-router";
import TagManager from 'react-gtm-module';



const HEADER_TITLE: any = {
    "home": "",
    "searchevents": "Event Search",
    "register": "Registrarse en el evento",
    "receipt": "Confirmación de registro",
    "confirmation": "Confirmación de registro"
}

class Header extends React.PureComponent<RouteComponentProps<{}>, any> {
    constructor(props: any) {
        super(props);
        this.goHome = this.goHome.bind(this);
        this.handleDismissClick = this.handleDismissClick.bind(this);
    }

    componentDidMount() {        
        if (process.env.REACT_APP_ENV === 'stage') {
            const tagManagerArgs = {
                gtmId: 'GTM-123456',
                dataLayerName: 'dataLayer'
            }

            TagManager.initialize(tagManagerArgs);
        }
        var divCookie = document.getElementById('divBannerCookieNotification')
        if (document.cookie.indexOf('cookie_notification') > -1) {
            var cookie = document.cookie.substring(document.cookie.indexOf('cookie_notification'));
            var dismissed = cookie.indexOf(';') > -1 ? cookie.substring(cookie.indexOf('=') + 1, cookie.indexOf(';')) : cookie.substring(cookie.indexOf('=') + 1);

            // dismissed = 'Always Show'; // Comment this line to function COrrectly 

            if (dismissed !== 'true') {
                if (divCookie != null)
                    divCookie.style.display = 'block';
            }
        } else {
            if (divCookie != null)
                divCookie.style.display = 'block';
        }

        //Add view port to handle mobile resolution
        var viewPortTag = document.createElement('meta');
        viewPortTag.id = "viewport";
        viewPortTag.name = "viewport";
        viewPortTag.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
        document.getElementsByTagName('head')[0].appendChild(viewPortTag);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    handleDismissClick() {
        var divCookie = document.getElementById('divBannerCookieNotification');
        document.cookie = 'cookie_notification=true; path=/';
        if (divCookie != null)
            divCookie.style.display = 'none';

    }

    private goHome(e: any) {
        e.preventDefault();
        //this.props.history.push("/");

        var confirmationPage = window.location.pathname;
        if (confirmationPage.includes("/confirmation/")) {
            this.props.history.push("/");
            if (window.location.pathname == "/") {
                var divISI = document.getElementById('fixed-isi');
                if (divISI != null) {
                    divISI.style.top = "calc(100% - 245px)";
                    divISI.classList.remove("isi-top-inactive");
                }
            }
        }
        else {
            this.props.history.push("/");
        }
    }

    render() {        
        let pathname = this.props.location.pathname;
        let pathnameArr = pathname.split("/");
        let headerTitle = pathnameArr[1] ? HEADER_TITLE[pathnameArr[1].toLocaleLowerCase()] : "";
        if (headerTitle == undefined)
            headerTitle = "Event Registration";

        return (
            <div>
                <div id="divBannerCookieNotification" style={{ display: "none" }}>
                    <div className="pure-g" id="cookie-container">
                        <div className="pure-u-22-24">                            
                            Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al continuar usando este sitio web, usted acepta que usemos estas cookies. Para obtener más información sobre cómo usamos y administramos las cookies, consulte nuestra <a href="https://www.biocryst.com/global-privacy-notice/" target="_blank">Política de privacidad</a> y <a href="https://orladeyo.com/politica-de-cookies/" target="_blank">Política de cookies</a>.
            </div>
                        <div className="pure-u-2-24">
                            <img src={Dismiss} className="pure-img" id="dismiss-notification" alt="Dismiss cookie notification" onClick={this.handleDismissClick} />
                        </div>
                    </div>

                </div>
                <div id="divBannerCovid19Notification" style={{ display:"none" }}>
                    <div className="pure-g" id="covid19-container">
                        <div className="covid-text">
                            <div className="covid-text-1">
                                Due to COVID-19, live events are currently virtual, not in-person.
                            </div>
                        </div>
                    </div>
                </div>
                {headerTitle ?
                    <header >
                        <div style={{ backgroundColor: "#007298", height: "34px", width: "100%" }}>
                            <div className="headerTitle">
                                <h3 style={{ color: "white", textAlign: "center" }}>{headerTitle}</h3>
                            </div>
                            <div className="headerHome">
                                <a style={{ color: "#FFF", display: "block", textDecoration: "none" }} href="#" onClick={this.goHome}><h3 style={{ color:"white" }}>Inicio</h3></a>
                            </div>
                        </div>
                        <div>
                            <div style={{ padding: "0px 10px 0px 10px", marginTop: "10px" }}>
                                <div id="header-title" className="header__logo">
                                    <div className="row">
                                        <div className="ImgColumn">
                                            <div className="img_BioCryst">
                                                <img src={BioCryst} alt="BioCryst" className="img-header-logo" />
                                            </div>
                                        </div>
                                        <div className="ImgColumn">
                                            <div className="img_Orladeyo">
                                                <img src={Orladeyo} alt="Orladeyo (berotralstat) capsules 150 mg" className="img-header-logo" />
                                            </div>
                                        </div>
                                        <div className="ImgColumn">
                                            <div className="img_Empower">
                                                <img src={Empower} alt="Empower Patient Services" className="img-header-logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </header> : <header></header>
                }
            </div >
        );
    }
}

export default withRouter(Header);