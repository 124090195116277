import * as React from 'react';
import { RouteComponentProps, matchPath } from 'react-router';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as DefaultStore from '../store/Register';
import '@progress/kendo-theme-default/dist/all.css';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BioCryst from '../Content/Images/Logo/BioCryst_Logo_Reg.png';
import Orladeyo from '../Content/Images/Logo/Orladeyo_Logo.png';
import Empower from '../Content/Images/Logo/EMPOWER_Logo.png';
import ReactHtmlParser from 'react-html-parser';
import Footer from './Footer';
import { load } from '@progress/kendo-react-intl';

type DefaultProps =
    DefaultStore.RegisterState &
    typeof DefaultStore.actionCreators &
    RouteComponentProps<{}>;

export interface EventInfo {
    EventId: string;
    Program: string;
    Location: string;
    Speaker: string;
}

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

const loadingPanel = (
    <div className="k-loading-mask">
        <div className="k-loading-image" role="alert" aria-label="Procesando"></div>
        <div className="k-loading-color"></div>
    </div>
);

export class Default extends React.PureComponent<DefaultProps, any> {
    constructor(props: DefaultProps) {
        super(props);
        this.handleGridDataStateChange = this.handleGridDataStateChange.bind(this);
        this.handleDropdownStateChange = this.handleDropdownStateChange.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.handleSearchEvents = this.handleSearchEvents.bind(this);
        this.registerClick = this.registerClick.bind(this);
        this.handleLiveEvents = this.handleLiveEvents.bind(this);
        this.handleVirtualEvents = this.handleVirtualEvents.bind(this);
        this.handleScrollClick = this.handleScrollClick.bind(this);
        this.handleTogglediv = this.handleTogglediv.bind(this);

        this.state = {
            AllRegistrants: [],
            radius: [
                { text: '10 millas', Value: 10 },
                { text: '25 millas', Value: 25 }
            ],
            inputVal: "",
            value: { text: '10 millas', Value: 10 },
            EventSearchDates: this.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth()))) + ' - ' + this.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth() + 4))),
            FromdefaultValue: new Date(new Date().setMonth(new Date().getMonth())),
            ToDefaultValue: new Date(new Date().setMonth(new Date().getMonth() + 4)),
            LiveEvent: 'live',
            VirtualEvent: 'virtual',
            gridDataState: {
                sort: [
                    { field: "EvtName", dir: "" }
                ],
                page: { skip: 0, take: 10 }
            },
            ShowLoader: true

        };
    }

    componentDidMount() {
        this.GetSearchEvents();

        //Zipcode
        var varZipcode = document.getElementById("txtZipcode");
        if (varZipcode != null) {
            var attZipcode = document.createAttribute("inputmode");
            attZipcode.value = "numeric";
            varZipcode.setAttributeNode(attZipcode);
        }

        //Adjust page height as per ISI mode
        var divISI = document.getElementById('fixed-isi');
        if (divISI != null) {
            divISI.style.top = "calc(100% - 245px)";

            if (divISI.classList.contains('isi-top-inactive')) {
                var divHomeHero = document.getElementById('home-hero')
                if (divHomeHero != null) {
                    divHomeHero.style.height = "auto";
                    divHomeHero.style.paddingBottom = '0px';
                }
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.LiveEvent !== prevState.LiveEvent || this.state.VirtualEvent !== prevState.VirtualEvent) {
            this.GetSearchEvents();
            this.setState({ inputVal: '' });
        }
    }

    handleScrollClick = () => {
        var input = document.getElementById('divAboutThePrograms')
        if (input != null)
            window.scrollTo(0, input.offsetTop);
    }

    handleTogglediv = () => {
        var searchFilters = document.getElementById('divSearchFilters')
        if (searchFilters != null) {

            if (searchFilters.style.display == 'none') {
                searchFilters.style.display = 'block';
                var searchEventsToggle = document.getElementById('spanSearchEventsToggle')
                if (searchEventsToggle != null)
                    searchEventsToggle.innerHTML = ("Cerrar búsqueda<i class='up'></i>");
            }
            else {
                var searchFilters = document.getElementById('divSearchFilters')
                if (searchFilters != null)
                    searchFilters.style.display = 'none';
                var searchEventsToggle = document.getElementById('spanSearchEventsToggle')
                if (searchEventsToggle != null)
                    searchEventsToggle.innerHTML = ("Búsqueda avanzada<i class='down'></i>");
            }
        }
    }

    handleLiveEvents = (e: any) => {
        this.setState({ ShowLoader: true });
        var spanSearchLocation = document.getElementById('spanSearchLocation');
        var tdziptext = document.getElementById('tdziptext');
        var tdradius = document.getElementById('tdradius');
        if (e.target.checked) {
            this.setState({ LiveEvent: e.target.value });
            if (spanSearchLocation != null && tdziptext != null && tdradius != null) {
                spanSearchLocation.style.display = '';
                tdziptext.style.display = '';
                tdradius.style.display = '';
            }
        }
        else {
            this.setState({ LiveEvent: '' });
            if (spanSearchLocation != null && tdziptext != null && tdradius != null) {
                spanSearchLocation.style.display = 'none';
                tdziptext.style.display = 'none';
                tdradius.style.display = 'none';
            }
        }

    }

    handleVirtualEvents = (e: any) => {
        this.setState({ ShowLoader: true });
        if (e.target.checked) {
            this.setState({ VirtualEvent: e.target.value });
        }
        else {
            this.setState({ VirtualEvent: "" });
        }
    }

    registerClick = (e: any) => {
        this.props.setEventID(e);
        this.props.history.push("/register");
    }

    handleGridDataStateChange = (e: any) => {
        this.setState({ gridDataState: e.data });
    }

    handleDropdownStateChange = (event: any) => {
        this.setState({ value: event.target.value });
    }

    inputChangeHandler = (event: any) => {
        this.setState({ inputVal: event.target.value });
    }

    handleSearchEvents = (event: any) => {
        event.preventDefault();
        this.setState({ ShowLoader: true });
        this.GetSearchEvents();
        this.setState({ EventSearchDates: this.getFormattedDate(this.state.FromdefaultValue) + ' - ' + this.getFormattedDate(this.state.ToDefaultValue) })
    }

    GetSearchEvents() {
        const queryStr = serialize({ sEventTypeLive: this.state.LiveEvent, sEventTypeVirtual: this.state.VirtualEvent, sZipcode: this.state.inputVal, sRadius: this.state.value.Value, sFromDate: this.state.FromdefaultValue.toJSON(), sToDate: this.state.ToDefaultValue.toJSON() })
        const base_url = 'api/Registration/GetSearchEvents';
        const init = { method: 'GET', accept: 'application/json', headers: {} };

        fetch(`${base_url}?${queryStr}`, init).then((response) => response.json() as Promise<Array<EventInfo[]>>)
            .then(data => {
                this.setState({ AllRegistrants: data });
                this.setState({ ShowLoader: false });
            }).catch(error => {
                this.setState({ ShowLoader: false });
                console.log(error);
            });
    }

    getFormattedDate(date: any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(1, '0');
        let day = date.getDate().toString().padStart(1, '0');

        return month + '/' + day + '/' + year;
    }

    public render() {
        let contents = this.renderCreateForm();

        return <div>
            {contents}
        </div>;
    }

    // Returns the HTML Form to the render() method.
    private renderCreateForm() {
        const minDate = new Date("1/1/2000");
        return (
            <div id="home-hero" className="hero" >
                <header className="header">
                    <div className="right_nav">
                        <a href="https://orladeyoeventspatient.com/" target="_blank" className="header__link"><h3 className="h3_white_color">Inglés</h3></a>
                        <a href="https://www.biocryst.com/wp-content/uploads/2020/12/ORLADEYO_PI_V1_2020.pdf" target="_blank" className="header__link"><h3 className="h3_white_color">Información de prescripción</h3></a>
                        <a href="https://orladeyo.com/espanol/" target="_blank" className="header__link"><h3 className="h3_white_color">Información importante de seguridad</h3></a>
                        <h3 className="header__link h3_white_color" style={{ marginLeft: "20px", marginRight: "12px", position: "absolute", right: "244px" }} >Este programa está destinado únicamente a residentes estadounidenses.</h3>
                    </div>
                    <a href="javascript:void(0)" className="aTab" onClick={this.handleScrollClick}><h3 className="h3_white_color">ACERCA DE LOS PROGRAMAS</h3></a>
                </header>
                <main className="main">
                    <section className="content">
                        <div className="header__logo">
                            <div className="row">
                                <div className="ImgColumn">
                                    <div className="img_BioCryst">
                                        <img src={BioCryst} alt="BioCryst" className="img-header-logo" />
                                    </div>
                                </div>
                                <div className="ImgColumn">
                                    <div className="img_Orladeyo">
                                        <img src={Orladeyo} alt="Orladeyo (berotralstat) capsules 150 mg" className="img-header-logo" />
                                    </div>
                                </div>
                                <div className="ImgColumn">
                                    <div className="img_Empower">
                                        <img src={Empower} alt="Empower Patient Services" className="img-header-logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content__inner">
                            <div className="welcomeMessage">
                                <p>Participe en un programa educativo GRATIS sobre la primera y única terapia oral diseñada específicamente para prevenir ataques de angioedema hereditario (AEH).</p>
                                <div className="divProgramcheckbox">
                                    <span className="programtypes"><h3 className="h3_black_color">TIPOS DE PROGRAMAS</h3></span>
                                    <div className="divProgramcheckbox">
                                        <div className="divDisplayFlex">
                                            <div className="columnCheckbox">
                                                <input type="checkbox" id="liveprogram" name="liveprogram" aria-label="programaenvivo" value="live" defaultChecked onChange={e => this.handleLiveEvents(e)} />
                                                <label className="labelcheckboxes">Programa en vivo</label>
                                            </div>
                                            <div className="columnCheckbox">
                                                <input type="checkbox" id="virtualprogram" name="virtualprogram" aria-label="programavirtual" value="virtual" defaultChecked onChange={e => this.handleVirtualEvents(e)} />
                                                <label className="labelcheckboxes">Programa virtual</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="togglediv">
                                    <div>
                                        <a href="javascript:void(0)" onClick={this.handleTogglediv}><span id="spanSearchEventsToggle">Búsqueda avanzada<i className="down"></i></span></a>
                                    </div>
                                </div>
                                <div id="divSearchFilters" className="SearchFilters" style={{ display: "none" }}>
                                    <form onSubmit={this.handleSearchEvents}>
                                        <div>
                                            <div className="columnZip colSearchDates">
                                                <div>
                                                    <span className="section_title">Buscar fechas</span> <span className="date_red">
                                                        <span style={{ color: "#9c3131", fontSize: "12px", paddingLeft: "5px" }}>{this.state.EventSearchDates}</span></span> <a className="halflings refresh glyphicons" href="javascript:window.location.href = window.location.href"></a>
                                                </div>
                                                <br />
                                                <div className="colDates divDisplayFlex">
                                                    <div>
                                                        <div className="filters_ColumnTitles"><span className="fieldlabel">Desde:</span> </div>
                                                        <DatePicker name="FromDate" format={"M/d/yyyy"} width="110px" min={minDate} valid={(this.state.FromdefaultValue !== null && this.state.ToDefaultValue !== null)
                                                            && (this.state.FromdefaultValue.getTime() < this.state.ToDefaultValue.getTime()) && this.state.FromdefaultValue > minDate} validationMessage={(this.state.FromdefaultValue === null || this.state.FromdefaultValue < minDate) ? 'Please enter valid date' : 'From date cannot be after To date!'} defaultValue={this.state.FromdefaultValue} onChange={(e) => this.setState({ FromdefaultValue: e.target.value })} />
                                                    </div>
                                                    <div style={{ marginLeft: "2px" }}>
                                                        <div className="filters_ColumnTitles"><span className="fieldlabel">Para:</span> </div>
                                                        <DatePicker name="ToDate" format={"M/d/yyyy"} width="110px" min={minDate} valid={(this.state.FromdefaultValue !== null && this.state.ToDefaultValue !== null)
                                                            && (this.state.FromdefaultValue.getTime() < this.state.ToDefaultValue.getTime()) && this.state.ToDefaultValue > minDate} validationMessage={this.state.ToDefaultValue === null || this.state.ToDefaultValue < minDate ? 'Please enter valid date' : 'To date cannot be before From date!'} defaultValue={this.state.ToDefaultValue} onChange={(e) => this.setState({ ToDefaultValue: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="columnZip colZipCodeSearch">
                                                <div className="divSearchLocation">
                                                    <span id="spanSearchLocation" className="section_title">Buscar ubicación</span>
                                                </div>
                                                <br />
                                                <div className="divDisplayFlex">
                                                    <div id="tdziptext" style={{ paddingTop:"11px" }}>
                                                        <Input type="number" placeholder="Código postal" aria-label="códigopostal" id="txtZipcode" value={this.state.inputVal} maxLength={5} valid={(this.state.inputVal !== null && (this.state.inputVal.length === 0 || this.state.inputVal.length === 5))} validationMessage={'Ingrese un código postal válido'} onChange={this.inputChangeHandler} style={{ border: "3px solid #83A0B0", width: "110px", fontWeight: 500 }} />
                                                    </div>
                                                    <div id="tdradius" className="tdradius">
                                                        <div className="filters_ColumnTitles"><span className="fieldlabel">Radio:</span> </div>
                                                        <DropDownList data={this.state.radius}
                                                            textField="text" style={{ width: "110px" }}
                                                            dataItemKey="Value" value={this.state.value} onChange={this.handleDropdownStateChange} />
                                                    </div>
                                                    <div className="td_btn_search">
                                                        <button type="submit" className="k-primary k-button homeSearchButton"> Buscar</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="GridContainer">
                                    {this.state.ShowLoader && loadingPanel}
                                    <Grid style={{ borderColor: "black" }}
                                        data={process(this.state.AllRegistrants, this.state.gridDataState)}
                                        pageable={false}
                                        sortable={false}
                                        {...this.state.gridDataState}
                                        filterable={false}
                                        onDataStateChange={this.handleGridDataStateChange}
                                        scrollable="none"
                                    >

                                        <Column headerClassName="gridHeader"
                                            field="eventId"
                                            title="Registrarse"
                                            cell={(props) => props.dataItem.eventId ?
                                                <td>
                                                    <button style={{ backgroundColor: "#007298", borderColor: "#007298" }}
                                                        className="k-primary k-button"
                                                        onClick={(e) => this.registerClick(props.dataItem.eventId)}
                                                    > Registrarse
                                                    </button>
                                                </td> : <td></td>
                                            }
                                        />
                                        <Column headerClassName="gridHeader"
                                            field="program"
                                            title="Programa"
                                            cell={(props) => <td><span>{ReactHtmlParser(props.dataItem.program)}</span></td>}
                                        />
                                        <Column headerClassName="gridHeader"
                                            field="location"
                                            title="Ubicación"
                                            cell={(props) => <td><span>{ReactHtmlParser(props.dataItem.location)}</span></td>}
                                        />
                                        <Column headerClassName="gridHeader"
                                            field="speaker"
                                            title="Orador(es)"
                                            cell={(props) => <td><span>{ReactHtmlParser(props.dataItem.speaker)}</span></td>}
                                        />
                                        <GridNoRecords>
                                            {!this.state.ShowLoader ?
                                                <div style={{ textAlign: 'left' }}><span className='norecords'>
                                                    Desafortunadamente, no hay programas en el rango de fechas seleccionado en este momento. Vuelva a consultar para conocer las actualizaciones de la ubicación del programa o si tiene preguntas, llame al 1-844-574-0037 o correo electrónico <a style={{ color: '#0078ab' }} href='mailto:Support@ORLADEYOeventspatient.com' >Support@ORLADEYOeventspatient.com</a></span></div> : <div></div>
                                            }
                                        </GridNoRecords>
                                    </Grid>
                                </div>
                                <p className="text-center"><a href="https://orladeyo.com/espanol/" target="_blank">Haga clic aquí</a> para visitar el sitio web de ORLADEYO y obtener más información antes de unirse al programa.</p>

                                <div className="divAboutPrograms" id="divAboutThePrograms">
                                    <span id="aboutprograms">ACERCA DE LOS PROGRAMAS</span>
                                    <p>Esta serie de programas educativos en vivo sobre ORLADEYO<sup>&reg;</sup> (berotralstat) ofrece a las personas que viven con AEH y a sus cuidadores la oportunidad de hacer lo siguiente:</p>
                                    <ul className="feature-list">
                                        <li>Pensar en cómo el AEH y los tratamientos pueden haber alterado su vida e identificar los factores a tener en cuenta para encontrar la terapia preventiva adecuada para usted.</li>
                                        <li>Conocer ORLADEYO<sup>&reg;</sup> (berotralstat), la primera y única terapia oral diseñada específicamente para prevenir ataques de AEH.</li>
                                        <li>Escuchar a alguien que vive con AEH y conocer su trayectoria y experiencia con ORLADEYO.</li>
                                        <li>Averiguar cómo obtener apoyo personalizado, obtener acceso a ORLADEYO e iniciar la terapia.</li>
                                        <li>Obtener respuestas a sus preguntas durante una sesión de preguntas y respuestas, y conectarse con otras personas que viven con AEH y sus cuidadores al final del programa.</li>
                                    </ul>
                                    <p>
                                        Si tiene preguntas o desea inscribirse en un programa, comuníquese al:<br className="newLineMedia"></br>
                                        1-844-574-0037 o a la dirección <a className="supportEmail" href="mailto:Support@ORLADEYOeventspatient.com">Support@ORLADEYOeventspatient.com</a>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <div><Footer /></div>
            </div >
        )
    }
}
export default connect(
    (state: ApplicationState) => state.register,
    DefaultStore.actionCreators
)(Default as any);
