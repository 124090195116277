import * as React from 'react';
import { connect } from 'react-redux';
import logo from '../Content/Images/Logo/Capri_logowS4C.png';
import TranscendLogo from '../Content/Images/Logo/Transcend_White.png';

export class Footer extends React.PureComponent<any, any> {
    //static displayName = Footer.name;       

    render() {
        return (
            <div>
                <footer className="footer">
                    <div className="row">
                        <div className="column">
                            <div className="footer_capri" style={{ padding: "4px" }}>
                                <img src={logo} alt="CAPRI | Sign4compliance Comprehensive Medical Meeting Management System" style={{ width: "200px" }} /><br />
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <div>Desarrollado por:</div>
                                        <div style={{ paddingLeft: "7px" }}>CapriMeetingManager.com</div>
                                    </div>
                                    <div style={{ padding: "3px 0px 0px 91px" }}>Sign4Compliance.com</div>
                                    <div style={{ paddingTop: "3px" }}>Copyright 2023 Blue Grotto Technologies, Inc.</div>
                                </div>
                            </div>
                        </div>
                        <div className="column">

                            <div>
                                <div style={{ textAlign: "center", left: "10px", fontSize: "15px", margin: "0 auto" }}>
                                    <a href="https://www.biocryst.com/terms-and-conditions/" target="_blank" style={{ color: "#ffffff" }}>Términos de uso</a>
                                    &nbsp;|&nbsp;
                                    <a href="https://orladeyo.com/politica-de-cookies/" target="_blank" style={{ color: "#ffffff" }}>Política de cookies</a>
                                    &nbsp;|&nbsp;
                                    <a href="https://www.biocryst.com/global-privacy-notice/" target="_blank" style={{ color: "#ffffff" }}>Política de privacidad</a>&nbsp;&nbsp;&nbsp;
                                </div>

                                <div style={{ textAlign: "center", marginTop: "40px", fontSize: "13px", lineHeight: "18px", color: "#ffffff" }}>
                                    <div>
                                        <span>BioCryst y ORLADEYO son marcas registradas de BioCryst Pharmaceuticals, Inc.</span>
                                    </div>
                                    <div>
                                        <span>© 2023 BioCryst Pharmaceuticals, Inc. Todos los derechos reservados.</span>
                                    </div>
                                </div>

                                <br></br>
                                <div style={{ border: "2px solid #d3d3d3", color: "#ffffff", width: "280px", padding: "5px 10px", fontSize: "13px", display: "none" }}>
                                    Estos programas están destinados a proporcionar información sobre la primera terapia profiláctica oral para personas que viven con angioedema hereditario (HAE).                                    </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="footer_managedby">
                                <div>
                                    <span style={{ float: "left", fontSize: "12px", color: "#ffffff" }}>Administrado por:</span>
                                </div>
                                <div>
                                    <img src={TranscendLogo} alt="Transcend Medical Communications" width="200" height="90" className="transcendLogo" />
                                    <br></br>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ fontSize: "12px", color: "#ffffff" }} id="spProjectCode">US.ORL.01729 11/23</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div >
        );
    }
}
export default connect()(Footer);